import { LOCALSTORAGE_KEY } from "./constants"
import { getItem } from "./localstorage"

export const isLoggedIn = () => {
	return getItem(LOCALSTORAGE_KEY) != null
}

export const handleChangeNumeric = (val, setFn) => {
	const re = /^[0-9\b]+$/;

	if (val === '') {
		setFn(0)
	}

	if (re.test(val)) {
		setFn(parseInt(val))
	}
}

export const getBaseUrl = () => {
	return process.env.REACT_APP_API_URL
}

export const getUpladsUrl = () => {
	return process.env.REACT_APP_API_URL + 'uploads/'
}

export const convertBytesToKB = (bytes) => Math.round(bytes / 1000)

export const isFunction = (fn) => { return typeof fn === 'function' }

export const getTextFromBoolean = (boolVal) => boolVal ? 'Sí' : 'No'

export const capitalizeWord = (word) => word.charAt(0).toUpperCase() + word.slice(1)

export const roundToTwo = (num) => {
	return + (Math.round(num + "e+3") + "e-2") * 10;
}

// Recibe un objeto y devuelve una copia con los campos vacíos reemplazados por null
export const replaceEmptyWithNull = (obj) => {
	const copy = { ...obj }

	for (let property in copy) {
		let value = copy[property]
		// Si el valor es una cadena vacía, lo reemplaza por null
		if (value === "") {
			copy[property] = null
		}

		if (typeof value === "object") {
			copy[property] = replaceEmptyWithNull(value)
		}
	}

	return copy
}

// Recibe un objeto y retorna el mismo objeto sin las propiedades que sean null
export const getObjectWithoutNullOrUndefinedKeys = (obj) => {
	// Obtener el array de pares [clave, valor] del objeto
	const entries = Object.entries(obj ?? {})
	console.log('entries', entries)
	// Filtrar el array para quedarse solo con los pares cuyo valor no sea null
	const filteredEntries = entries.filter(([key, value]) => value !== null && value !== undefined)
	console.log('filteredEntries', filteredEntries)
	
	const newObj = Object.fromEntries(filteredEntries)
	
	return newObj
}

export const allKeysNull = (obj) => {
	for (let key in obj) {
		// Si el objeto tiene una propiedad propia que no es null, retornar false
		if (obj.hasOwnProperty(key) && obj[key] !== null) {
			return false
		}
	}

	// Si todas las propiedades son null o heredadas, retornar true
	return true
}

export const getLastNYears = (n) => {
	const year = new Date().getFullYear()
	
	const years = []
	for (let i = year; i >= year - n + 1; i--) {
		years.push(i)
	}

	return years
}